
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_space_4.svg'
import shape2 from '../../../images/shapes/shape_angle_3.webp'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="faq_section section_decoration">
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                        F.A.Q.
                    </div>
                    <h2 className="heading_text mb-0">
                        Need a <mark>Support?</mark>
                    </h2>
                </div>

                <div className="faq_accordion accordion" id="faq_accordion">
                    <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="1">
                                Q. How to choose a software development company?
                            </AccordionHeader>
                            <AccordionBody accordionId="1" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Choose a company with a strong portfolio in your required software type. Ensure they offer a range of services including web and mobile app development, IT consulting, software architecture, and QA testing. Look for a good reputation, technical expertise, and clear communication.
                                </p>
                               
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="2">
                                Q. What does your audit and IT consulting service include?
                            </AccordionHeader>
                            <AccordionBody accordionId="2" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Our audit and IT consulting services include evaluating your current IT systems, identifying areas for improvement, and providing expert advice on optimizing your technology strategy. This helps ensure your IT infrastructure is efficient, secure, and aligned with your business goals.
                                </p>
                                
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="3">
                                Q. What is web application design and development?
                            </AccordionHeader>
                            <AccordionBody accordionId="3" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Web application design and development involves creating and building custom web-based applications that run in a web browser. This includes designing the user interface, developing functionality, and ensuring the application is responsive and user-friendly.
                                </p>
                                
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="4">
                                Q. What do you offer in mobile app design and development?
                            </AccordionHeader>
                            <AccordionBody accordionId="4" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Our mobile app design and development services include creating custom apps for smartphones and tablets. This covers everything from designing the user experience (UI/UX) to coding the app for both iOS and Android platforms, ensuring a seamless and engaging user experience.
                                </p>
                                
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="5">
                                Q. What are best UI/UX design services?
                            </AccordionHeader>
                            <AccordionBody accordionId="5" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Best UI/UX design services focus on creating intuitive and visually appealing user interfaces (UI) and experiences (UX) for applications and websites. This involves designing layouts, interactions, and user flows that enhance usability and satisfaction.
                                </p>
                                
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="5">
                                Q. What does your maintenance and customer support service cover?
                            </AccordionHeader>
                            <AccordionBody accordionId="5" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Our maintenance and customer support services include ongoing updates, bug fixes, and technical assistance for your software. We ensure that your applications continue to function smoothly, address any issues that arise, and provide support to help you effectively use the software.
                                </p>
                                
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Techco Shape Angle" />
            </div>
        </section>
    )
};
export default FaqSection;

import { useRef, useEffect } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import icon1 from '../../../images/icons/icon_c.svg';
import icon2 from '../../../images/icons/icon_g2.svg';
import tImg1 from '../../../images/avatar/avatar_5.png';
import tImg2 from '../../../images/avatar/avatar_6.webp';
import tImg3 from '../../../images/avatar/avatar_7.png';

import flagAustria from '../../../images/flag/austria_flag.webp';
import flagPakistan from '../../../images/flag/pakistan_flag.webp';
import flagUK from '../../../images/flag/uk_flag.webp';

const testimonial = [
    {
        id: '01',
        tImg: tImg1,
        Title: 'Amazing software services',
        Des: "The solutions they're providing is helping our business run more smoothly. We've been able to make quick developments with them, meeting our product vision within the timeline we set up. Listen to them because they can give strong advice about how to build good products.",
        Name: 'Drazen Ivanis',
        sub: 'Board Member, Wow flow',
        country: 'Vienna, Austria',
        flag: flagAustria,
    },
    {
        id: '02',
        tImg: tImg2,
        Title: 'Made Our Sales and Operations a Breeze',
        Des: "The POS system provided by [Your Company Name] has streamlined our operations and significantly improved our sales tracking. The integration was seamless, and their support team was incredibly responsive. It’s been a crucial upgrade for our business.",
        Name: 'Faisal Aslam',
        sub: 'Owner',
        country: 'Karachi, Pakistan',
        flag: flagPakistan,
    },
    {
        id: '03',
        tImg: tImg3,
        Title: 'Modernizing Our Online Presence',
        Des: "Working with AWF GHANI has been a game-changer for us. Their team developed a stunning app and website that perfectly captures our brand and engages our customers. The attention to detail and creativity exceeded our expectations. Highly recommend their services!",
        Name: 'M. Faizan',
        sub: 'Marketing Director at RS Web',
        country: 'London, United Kingdom',
        flag: flagUK,
    },
];

const Testimonial = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current && prevRef.current && nextRef.current) {
            swiperRef.current.params.navigation.prevEl = prevRef.current;
            swiperRef.current.params.navigation.nextEl = nextRef.current;
            swiperRef.current.navigation.init();
            swiperRef.current.navigation.update();
        }
    }, []);

    return (
        <div className="row">
            <div className="col-lg-4">
                <div className="deals_winner_customers">
                    <h3 className="title_text">
                        <mark>3,900+</mark> customers win deals with AWF GHANI
                    </h3>
                    <div className="row">
                        <div className="col-6">
                            <div className="review_short_info">
                                <div className="icon">
                                    <img src={icon1} alt="C SVG Icon" />    
                                </div>
                                <ul className="rating_block unordered_list">
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                </ul>
                                <div className="review_counter">From <b>200+</b> reviews</div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="review_short_info">
                                <div className="icon">
                                    <img src={icon2} alt="C SVG Icon" />
                                </div>
                                <ul className="rating_block unordered_list">
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                </ul>
                                <div className="review_counter">From <b>300+</b> reviews</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-8">
                <div className="review_onecol_wrapper">
                    <div className="review_onecol_carousel">
                        <Swiper
                            // install Swiper modules
                            modules={[Navigation]}
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}
                            speed={1800}
                            parallax={true}
                            ref={swiperRef}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                        >
                            {testimonial.map((item) => (
                                <SwiperSlide key={item.id}>
                                    <div className="review_block_2">
                                        <h3 className="review_title">“{item.Title}”</h3>
                                        <p className="review_commtent">
                                            {item.Des}
                                        </p>
                                        <div className="d-md-flex justify-content-md-between">
                                            <div className="review_admin">
                                                <div className="review_admin_image">
                                                    <img src={item.tImg} alt={item.Name} />
                                                </div>
                                                <div className="review_admin_info">
                                                    <h4 className="review_admin_name">{item.Name}</h4>
                                                    <span className="review_admin_designation">{item.sub}</span>
                                                    <div className="review_admin_country">
                                                        <span className="country_flag">
                                                            <img src={item.flag} alt={`${item.country} Flag`} />
                                                        </span>
                                                        <span className="country_text">{item.country}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="review_admin_logo">
                                                {/* <img src={clogo} alt="Review Admin Logo" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <div className="carousel_arrows_nav">
                            <button ref={prevRef} type="button" className="r1cc-swiper-button-prev">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <button ref={nextRef} type="button" className="r1cc-swiper-button-next">
                                <i className="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;

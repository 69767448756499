
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_line_2.svg'
import shape2 from '../../../images/shapes/shape_line_3.svg'
import shape3 from '../../../images/shapes/shape_line_4.svg'
import shape4 from '../../../images/shapes/shape_space_3.svg'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import TechnologySection from '../TechnologySection/TechnologySection';
import Testimonial from '../Testimonial/Testimonial';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="process_technology_review_section bg-light section_decoration">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="heading_block">
                            <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                Working Process
                            </div>
                            <h2 className="heading_text mb-0">
                                Our <mark>Approach</mark>
                            </h2>
                        </div>
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="1">
                                    01. Discovery Phase
                                </AccordionHeader>
                                <AccordionBody accordionId="1" className='acc_body'>
                                    <p className="m-0">
                                    At the start of every project, we embark on a Discovery Phase to understand your unique needs and goals. During this phase, our team will collaborate closely with you to gather all the necessary information, analyze your current systems, and define the project’s objectives. This thorough approach ensures we have a clear vision and a solid foundation to build upon, setting the stage for a successful project.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="2">
                                    02. Design and Development
                                </AccordionHeader>
                                <AccordionBody accordionId="2" className='acc_body'>
                                    <p className="m-0">
                                    Once we have a clear understanding of your needs, we move on to the Design and Development phase. Our talented designers create intuitive and attractive designs tailored to your requirements, while our developers bring these designs to life with cutting-edge technology. We focus on creating solutions that are not only visually appealing but also highly functional, ensuring that the end product meets your expectations and serves your business effectively.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="3">
                                    03. Maintenance
                                </AccordionHeader>
                                <AccordionBody accordionId="3" className='acc_body'>
                                    <p className="m-0">
                                    After your project is up and running, we offer ongoing Maintenance to ensure everything continues to operate smoothly. Our team is here to provide regular updates, address any issues that arise, and make improvements as needed. We’re committed to keeping your systems running efficiently, so you can focus on what you do best without worrying about technical glitches.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="4">
                                    04. Deployment
                                </AccordionHeader>
                                <AccordionBody accordionId="4" className='acc_body'>
                                    <p className="m-0">
                                    When your project is ready, we handle the Deployment process to launch it successfully. Our team ensures that the transition from development to live operation is seamless and smooth. We take care of all the technical aspects involved in deploying your solution, so you can start enjoying its benefits right away with minimal disruption to your business.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="5">
                                    05. Testing and QA
                                </AccordionHeader>
                                <AccordionBody accordionId="5" className='acc_body'>
                                    <p className="m-0">
                                    Before any project goes live, it undergoes rigorous Testing and Quality Assurance. Our QA team meticulously tests every feature and function to identify and resolve any issues. We ensure that your solution is reliable, secure, and performs as expected. Our goal is to deliver a high-quality product that meets your standards and provides a seamless user experience.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className="col-lg-5">
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <ul className="content_layer_group unordered_list_block text-center">
                                <li><AccordionHeader targetId="1"><span>Discovery Phase</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="2"><span>Design and Development</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="3"><span>Maintenance</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="4"><span>Deployment</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="5"><span>Testing and QA</span></AccordionHeader></li>
                            </ul>
                        </Accordion>
                    </div>
                </div>
                <TechnologySection/>
                <Testimonial/>
            </div>

            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="AWF GHANI Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="AWF GHANI Shape" />
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="AWF GHANI Shape" />
            </div>
            <div className="decoration_item shape_image_4">
                <img src={shape4} alt="AWF GHANI Shape" />
            </div>
        </section>
    )
};
export default FaqSection;
